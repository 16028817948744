.buttonEdit {
  opacity: 0.3;
  padding: 0 !important;
  padding-left: 0.3rem;
  filter: alpha(opacity=40);
}

.buttonEdit:hover {
  opacity: 1;
  color: blue;
  filter: alpha(opacity=100);
}

.buttonDelete {
  opacity: 0.3;
  padding: 0 !important;
  padding-left: 0.3rem;
  filter: alpha(opacity=40);
}

.buttonDelete:hover {
  opacity: 1;
  color: red;
  filter: alpha(opacity=100);
}
